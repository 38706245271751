import React, { FunctionComponent, useEffect } from "react";
import './css/about.css';
import { Carousel, Container, Row } from 'react-bootstrap';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import picture from './assets/about-us.jpeg';
import { useParams } from 'react-router-dom';
import { ApiUnsubscribe } from "./api/ApiUnsubscribe";

export const Unsubscribe: FunctionComponent = () => {
    const params = useParams()

    useEffect(() => {
        const myApi: ApiUnsubscribe = new ApiUnsubscribe();

        if (params.id != null) {
            myApi.unsubscribe(params.id).then();
        }
    }, [params.id]);

    return (
        <Container fluid>
            <Header />
            <Row>
                <Carousel controls={false} indicators={false}>
                    <Carousel.Item>
                        <img className="d-block w-100" src={picture} alt="First slide" />
                    </Carousel.Item>
                </Carousel>
            </Row>

            <Row className="justify-content-center">
                <h2>Désinscription</h2>
            </Row>

            <Row className="justify-content-center">
                <main>
                    <p>
                        Nous vous confirmons que conformément aux règles RGPD toutes vos données ont été supprimées de notre système.
                        <br />
                        Merci d'avoir pris le temps de vous désinscrire.
                    </p>
                </main>
            </Row>
            <Footer />
        </Container>
    );
};