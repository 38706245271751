import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './css/index.css';
import App from './App';
import About from './About';
import { Contact } from './Contact';
import { Unsubscribe} from "./Unsubscribe";
import { Gallery } from './Gallery';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<App />} />
              <Route path="/aboutus" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/unsubscribe/:id" element={<Unsubscribe />} />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);
