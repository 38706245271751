import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Footer } from './components/Footer'
import { Header } from './components/Header'
import './css/gallery.css';

const createRow = (index: number) => {
    return <Row key={index} style={{paddingLeft: "10px", paddingRight: "30px"}}>
        <Col lg={3} md={6} xs={12}><Image style={{ margin: "12px" }} fluid src={"/images/Image" + (index + 1) + ".jpg"} /></Col>
        <Col lg={3} md={6} xs={12}><Image style={{ margin: "12px" }} fluid src={"/images/Image" + (index + 2) + ".jpg"} /></Col>
        <Col lg={3} md={6} xs={12}><Image style={{ margin: "12px" }} fluid src={"/images/Image" + (index + 3) + ".jpg"} /></Col>
        <Col lg={3} md={6} xs={12}><Image style={{ margin: "12px" }} fluid src={"/images/Image" + (index + 4) + ".jpg"} /></Col>
    </Row>

}

const makeRows = (rowNumber: number) => {
    let rows: JSX.Element[] = []

    for (let i = 0; i < rowNumber; i++) {
        rows.push(createRow(i * 4));
    }

    return rows
}

export function Gallery() {
    return (
        <Container fluid>
            <Header />

            <Row style={{marginTop: "80px"}} className="myTitle">
                <Col>
                    <h2>Nos véhicules</h2>
                </Col>
            </Row>

            {makeRows(3)}

            <div style={{marginBottom: "30px"}}></div>

            <Footer />
        </Container>
    )
}
