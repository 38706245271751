import React from 'react';
import './css/about.css';
import { Carousel, Container, Row } from 'react-bootstrap';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import picture from './assets/about-us.jpeg';

function About() {
    return (
        <Container fluid>
            <Header />

            <Row>
                <Carousel controls={false} indicators={false}>
                    <Carousel.Item>
                        <img className="d-block w-100" src={picture} alt="First slide" />
                    </Carousel.Item>
                </Carousel>
            </Row>

            <Row className="justify-content-center">
                <h2>A propos</h2>
            </Row>

            <Row className="justify-content-center">
                <main>
                    <p className="text-justify">
                        Nous sommes une entreprise familiale, professionnelle et dynamique qui compte déjà 35 années d'expérience dans l'achat et la
                        vente de véhicules d'occasion de toute marque. Notre histoire commence dans les années 1980 quand Leonidas Papanikolaou crée
                        la société familiale <strong>LEONIDAS AUTO</strong>.
                        <br />
                        Au fil des années la société s'est fait connaître par son professionnalisme, son honnêteté, sa proximité et son contact
                        chaleureux. Elle lui a permit d'acquérir une clientèle fidèle avec laquelle elle travaille encore aujourd'hui.
                    </p>

                    <p className="text-justify">
                        Pascalis, son fils a ensuite repris l'entreprise familiale dans les années 90 et la nomme <strong>PLP MOTORS</strong>. La
                        société a changé de nom mais garde les mêmes valeurs instaurées par Leonidas. Elle resta donc le même partenaire de confiance
                        mais avec un dynamisme et entousiasme supplémentaire!
                    </p>

                    <p className="text-justify">
                        Nous travaillons depuis de nombreuses années avec les mêmes fournisseurs dont de nombreux concessionnaires tels que Mercedes,
                        Renault, ... pour la reprise de leurs véhicules d'occasion. Vous aurez donc la possibilité d'acquérir des véhicules entretenus
                        par des professionnels et disponibles immédiatement. Nous mettons un point d'honneur à proposer à nos clients des prix
                        attractifs et adaptés à leurs besoins et budget.
                    </p>
                </main>
            </Row>

            <Footer />
        </Container>
    );
}

export default About;