import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';
import React, { FunctionComponent } from 'react';
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';

export const Header: FunctionComponent = () => {
    return (
        <Navbar  bg="light">
            <Container className="justify-content-center">
                <Nav className={"myNav"} >
                    <Row>
                        <Col xs={"12"} sm={"3"}>
                            <Nav.Item className="myMenuItemArea">
                                <Nav.Link href={'/'} eventKey={'Home'} className="myMenuItem">
                                    Acceuil
                                </Nav.Link>
                            </Nav.Item>
                        </Col>
                        <Col xs={"12"} sm={"3"}>
                            <Nav.Item className="myMenuItemArea">
                                <Nav.Link href={'/aboutus'} eventKey={'About'} className="myMenuItem">
                                    A propos
                                </Nav.Link>
                            </Nav.Item>
                        </Col>
                        <Col xs={"12"} sm={"3"}>
                            <Nav.Item className="myMenuItemArea">
                                <Nav.Link href={'/contact'} eventKey={'Contact'} className="myMenuItem">
                                    Contact
                                </Nav.Link>
                            </Nav.Item>
                        </Col>
                        <Col xs={"12"} sm={"3"}>
                            <Nav.Item className="myMenuItemArea">
                                <Nav.Link href={'/gallery'} eventKey={'Gallery'} className="myMenuItem">
                                    Galerie
                                </Nav.Link>
                            </Nav.Item>
                        </Col>
                    </Row>
                </Nav>
            </Container>
        </Navbar>
    );
};