import React, { FunctionComponent } from 'react';
import './Footer.css';
import { Col, Row } from 'react-bootstrap';

export const Footer: FunctionComponent = () => {
    return (
        <>
            <Row className="myContainer">
                <Row>
                    <Col lg={4} md={4} xs={12}>
                        <Row className="justify-content-center">
                            <p className="myText">
                                <strong>PLP Motors</strong>
                                <br />
                                TVA : 0870.781.272
                            </p>
                        </Row>
                    </Col>

                    <Col lg={4} md={4} xs={12}>
                        <Row className="justify-content-center">
                            <p className="myText">
                                Moorstraat, 42
                                <br />
                                2870 Puurs
                            </p>
                        </Row>
                    </Col>

                    <Col lg={4} md={4} xs={12}>
                        <Row className="justify-content-center">
                            <p className="myText">
                                Tél : +32 (03) 293 32 29
                                <br />
                                Tél : +32 (0) 477 22 59 59
                            </p>
                        </Row>
                    </Col>
                </Row>
            </Row>
        </>
    );
};