import React, { FunctionComponent } from 'react';
import './css/contact.css';
import { Col, Container, Form, Row, Button, Jumbotron } from 'react-bootstrap';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import Background from './assets/contact_background.jpg';
import axios from 'axios';

export const Contact: FunctionComponent = () => {
    const onFormSubmit = (evt: any)=> {
        let msg: string;
        let email: string;
        let name: string;

        try {
            name = evt.target[0].value;
            email = evt.target[1].value;
            msg = evt.target[2].value;

            let data:any = {
                "Name": name,
                "Email": email,
                "Message": msg
            }

            axios.post(
                `https://api-plpmotors.azurewebsites.net/api/web/v1/sendEmail`,
                data
            ).catch((sq:any)=>console.log(sq))
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Container fluid>
            <Header />

            <Jumbotron fluid={true} style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover', margin: '-5%' }}>
                <Row className="myTitle">
                    <Col>
                        <h2>Contactez-nous</h2>
                    </Col>
                </Row>

                <Row>
                    <Col lg={{ offset: 3, span: 4 }} md={{ offset: 2, span: 6 }} xs={{ offset: 2, span: 8 }} className="myFormArea">
                        <Form onSubmit={onFormSubmit}>
                            <Form.Group controlId={'name'}>
                                <Form.Control type="text" placeholder="Votre nom" />
                            </Form.Group>

                            <Form.Group controlId={'email'}>
                                <Form.Control type="email" placeholder="Votre adresse e-mail" />
                            </Form.Group>

                            <Form.Group controlId={'yourText'}>
                                <Form.Control as="textarea" rows={4} placeholder="Votre message" />
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Envoyer
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Jumbotron>

            <Footer />
        </Container>
    );
};
