export class ApiUnsubscribe {
    public readonly _server: string;
    public readonly _port: number;

    constructor() {
        try {
            this._port = 8080;
            this._server = `http://walrus-api.plpmotors.be/`;
        } catch (error) {
            throw error;
        }
    }

    public async unsubscribe(id: string): Promise<string> {
        const response : Response = await fetch(this._server + 'api/v1/unsubscribe/' + id, ApiUnsubscribe.put({ Id: id }));
        return response.statusText
    }

    private static put(body: object): object {
        return {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        };
    }
}